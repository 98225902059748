import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import Title from '../components/title/title'
import styles from './article.module.css'
import { MDXRenderer } from "gatsby-plugin-mdx"

export default ({ data }) => {
	const post = data.mdx;

	return (
		<Layout>
			<div className={styles.container}>
				<Title title={post.frontmatter.title}></Title>
				<div></div>
				<div className={styles.content}>
					<MDXRenderer>{post.body}</MDXRenderer>
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query($slug: String!) {
		mdx(fields: { slug: { eq: $slug } }) {
			id
			excerpt
			body
			frontmatter {
				title
				keywords
			}
		}
	}
`
